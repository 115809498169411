import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  updateProfile,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
} from 'firebase/auth';
import GenericLoader from '../components/loader/GenericLoader';
import { apiRequest } from '../api/api';
import { logMessage } from '../utils/logger';
import { auth } from '../firebase/firebase';
import { getLocalStorageItem } from '../utils';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectResult, setRedirectResult] = useState(null);
  const [advancedBalanceMode, setAdvancedBalanceMode] = useState(false); // shows transcription minutes and insight credits instead of session credits

  const userData = JSON.parse(localStorage.getItem('currentUser'));

  const saveUserDataToLocalStorage = (user) => {
    const userData = {
      _id: user.DBuser._id,
      name: user.DBuser?.name || user.name,
      email: user.DBuser?.email || user.email,
      preferences: user.DBuser.preferences,
      allowedThemes: user.DBuser.allowedThemes,
      defaultTheme: user.DBuser.defaultTheme,
    };

    localStorage.setItem('currentUser', JSON.stringify(userData));
  };

  const resetAuthInProgress = () => {
    localStorage.removeItem('googleAuthInProgress');
    localStorage.removeItem('emailAuthInProgress');
  };

  const updateUserData = (newData) => {
    // Merge new data with existing user data
    const updatedUser = {
      ...currentUser,
      DBuser: {
        ...currentUser.DBuser,
        ...newData.DBuser,
      },
    };

    // Update state
    setCurrentUser(updatedUser);

    // Update localStorage
    saveUserDataToLocalStorage(updatedUser);
  };

  const forceLogout = async () => {
    window.localStorage.removeItem('currentUser'); // Remove user data from local storage
    await signOut(auth); // Sign out the user with Firebase
    setRedirectResult(null);
    setCurrentUser(null); // Clear the current user from the context
  };

  useEffect(() => {
    const auth = getAuth();
    const googleAuthInProgress = localStorage.getItem('googleAuthInProgress') === 'true';
    const emailAuthInProgress = localStorage.getItem('emailAuthInProgress') === 'true';
    const authInProgress = googleAuthInProgress || emailAuthInProgress;
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !user.DBuser) {
        try {
          if (googleAuthInProgress) {
            const result = await getRedirectResult(auth);
            if (result) {
              setRedirectResult(result);
            } else {
              setRedirectResult(null);
              await forceLogout();
            }
          } else if (!authInProgress) {
            user.DBuser = await getUserByFirebaseUid(user.uid);
            saveUserDataToLocalStorage(user);
          }

          // Check if reauthentication is needed
          if (user.DBuser && user.DBuser.config && user.DBuser.config.reauthenticate) {
            logMessage('debug', 'AuthProvider(). Reauthentication required. Signing out user.');
            await updateDBUser(user.DBuser._id, {
              config: {
                reauthenticate: false,
              },
            });
            await forceLogout();
            // After signing out, you may want to redirect the user to the login screen or show a notification
            return; // Skip setting the user as the current user since they are being signed out
          }
        } catch (error) {
          if (error.response?.status === 404 && !authInProgress) {
            logMessage('error', `AuthProvider(). User not found in the database: ${error.message}! Logging off...`);
            await forceLogout();
          }
          logMessage('error', `AuthProvider(). Error fetching user data: ${error.message}`);
        }
      }
      if (!authInProgress) {
        setCurrentUser(user);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <GenericLoader />
      </div>
    );
  }

  const refreshUser = async () => {
    if (currentUser && currentUser.DBuser) {
      try {
        // Fetch updated user data
        const updatedUser = await apiRequest('GET', `/user/${currentUser.DBuser._id}`);
        const newUserState = {
          ...currentUser,
          DBuser: updatedUser,
        };

        // Update state and return new user data
        setCurrentUser(newUserState);
        return newUserState;
      } catch (error) {
        logMessage('error', `AuthProvider(). Error refreshing user: ${error.message}`);
      }
    } else {
      console.log('AuthProvider(). RefreshUser(). No DBuser found. Fetching user data...');
      currentUser.DBuser = await getUserByFirebaseUid(currentUser.uid);
      saveUserDataToLocalStorage(currentUser);
    }
  };

  const refreshBalance = async () => {
    if (currentUser && currentUser.DBuser) {
      try {
        // Fetch updated balance
        const updatedBalance = await apiRequest('GET', `/user/balance/${currentUser.DBuser._id}`);
        setCurrentUser({
          ...currentUser,
          DBuser: {
            ...currentUser.DBuser,
            balance: updatedBalance,
          },
        });
      } catch (error) {
        logMessage('error', `AuthProvider(). Error refreshing balance: ${error.message}`);
      }
    }
  };

  const handleUserSignup = async (user) => {
    try {
      const newUserData = { email: user.email, name: user.displayName, firebaseUid: user.uid };
      const newDBUser = await checkExistingOrCreateNewUser(newUserData);
      if (!newDBUser || !newDBUser.user) {
        throw new Error('Error creating new user!');
      }
      user.DBuser = newDBUser.user;
      user.referralCode = getLocalStorageItem('referralCode') || null;
      if (newDBUser.isNew) {
        const updatedUser = await syncDBUserDetails(user);
        setCurrentUser(updatedUser);
        saveUserDataToLocalStorage(updatedUser);
      } else {
        setCurrentUser(user);
        saveUserDataToLocalStorage(user);
      }
    } catch (error) {
      throw new Error('Error signing up new user!', error);
    }
  };

  const handleUserLogin = async (user) => {
    try {
      const userExists = await doesUserEmailExist(user.email);
      if (!userExists) {
        throw new Error('User not found');
      } else {
        const syncedUser = await syncDBUserDetails(user);
        setCurrentUser(syncedUser);
        saveUserDataToLocalStorage(syncedUser);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        throw new Error('User not found');
      } else {
        throw error;
      }
    }
  };

  const isFeatureEnabled = (featureName) => {
    if (currentUser && currentUser.DBuser && currentUser.DBuser.features) {
      const feature = currentUser.DBuser.features.find((feature) => feature.name === featureName);
      return feature ? feature.enabled : false;
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userData,
        updateUserData,
        setCurrentUser,
        redirectResult,
        setRedirectResult,
        isFeatureEnabled,
        refreshUser,
        refreshBalance,
        advancedBalanceMode,
        setAdvancedBalanceMode,
        resetAuthInProgress,
        handleUserLogin,
        handleUserSignup,
        forceLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const syncDBUserDetails = async (user) => {
  try {
    let DBuser;
    DBuser = await getUserDataByEmail(user.email);
    if (!DBuser) {
      throw new Error('User not found in backend');
    }

    // Update user's firebaseUid and status in database and displayName in Firebase if they don't exist
    if (!user.displayName) {
      await updateFirebaseUserDisplayName(user, DBuser.name);
    }
    if (!DBuser.firebaseUid || DBuser.status !== 'active') {
      DBuser = await updateDBUser(DBuser._id, { firebaseUid: user.uid, status: 'active' });
    }

    // Update the user referral status if the user has a referral code
    if (user.referralCode) {
      await apiRequest('PUT', `/referral/${user.referralCode}`, {
        data: {
          hasJoined: true,
        },
      });
    }

    user.DBuser = DBuser;
    return user;
  } catch (error) {
    console.error('Error syncing DB user details:', error);
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Update the user data in the backend
export const updateDBUser = async (userId, userData) => {
  try {
    // Make an API request to update the user in the backend
    logMessage('DEBUG', `Updating user ${userId}:${JSON.stringify(userData)}`);
    const response = await apiRequest('PUT', `/user/${userId}`, { data: userData });
    logMessage('DEBUG', 'User updated successfully.');
    return response.user;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

// Function to update the user display name in FIrebase
export const updateFirebaseUserDisplayName = async (user, displayName) => {
  try {
    await updateProfile(user, { displayName });
    console.log('Display name updated successfully');
  } catch (error) {
    console.error('Error updating display name:', error);
    throw error;
  }
};

export const getUserDataByEmail = async (email) => {
  try {
    // Make an API request to retrieve the user data from the backend
    logMessage('DEBUG', `AuthCallback(). Retrieving user data for email:${email}`);
    const userData = await apiRequest('POST', '/user/find/email', {
      data: { email },
    });

    if (!userData) {
      return null;
    }
    return userData;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export const getUserByFirebaseUid = async (firebaseUid) => {
  try {
    // Make an API request to retrieve the user data from the backend
    const userData = await apiRequest('GET', `/user/firebaseUid/${firebaseUid}`);
    if (!userData) {
      throw new Error('No user found!');
    }
    return userData;
  } catch (error) {
    logMessage('ERROR', `getUserByFirebaseUid(). Error retrieving user data: ${error.message}`);
    throw error;
  }
};

export const doesUserEmailExist = async (email) => {
  try {
    // Make an API request to retrieve the user data from the backend
    const payload = {
      email,
    };
    const userData = await apiRequest('POST', `/user/exists`, { data: payload, usePublicApiKey: true });
    if (!userData) {
      throw new Error('No user found!');
    }
    return userData.exists;
  } catch (error) {
    return false;
  }
};

export const checkExistingOrCreateNewUser = async (userData) => {
  const { email, name, userType, companyName, photoUrl = null, firebaseUid = null, authMethod = null } = userData;
  let existingUser = null;
  let newUser = null;
  const status = firebaseUid ? 'active' : 'signedup';
  try {
    existingUser = await getUserDataByEmail(email);
  } catch (error) {
    console.error('Error getting user data by email:', error.message);
  }
  if (existingUser) {
    logMessage('DEBUG', `checkExistingOrCreateNewUser(). User already exists. Returning existing user data.`);
    return { user: existingUser, isNew: false };
  }

  try {
    const response = await apiRequest('POST', '/user', {
      data: {
        email,
        firebaseUid,
        status,
        name,
        userType,
        authMethod,
        companyName,
        photoUrl,
      },
    });
    newUser = response.user;
  } catch (error) {
    logMessage('ERROR', `checkExistingOrCreateNewUser(). Error signing up new user data: ${error.message}`);
  }
  return { user: newUser, isNew: newUser !== null };
};

export const signInWithGooglePopup = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    const result = await signInWithPopup(auth, provider);
    if (!result) {
      throw new Error('No user data found in Google account!');
    }

    const { user } = result;
    const { providerData } = user;
    const hasGoogleLogin = providerData.some((data) => data.providerId === 'google.com');
    const hasEmailLinkLogin = providerData.some((data) => data.providerId === 'password');

    const credential = GoogleAuthProvider.credentialFromResult(result);

    return { user, credential, hasGoogleLogin, hasEmailLinkLogin };
  } catch (error) {
    console.error('Error signing in with Google:', error.message);
    throw error;
  }
};

export const signInWithGoogleRedirect = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    const auth = getAuth();
    await signInWithRedirect(auth, provider);
  } catch (error) {
    console.error('Error signing in with Google:', error.message);
    throw error;
  }
};

export const getSignInResult = async () => {
  const auth = getAuth();
  const result = await getRedirectResult(auth);
  if (result && result.user) {
    const { user } = result;
    const { providerData } = user;
    const hasGoogleLogin = providerData.some((data) => data.providerId === 'google.com');
    const hasEmailLinkLogin = providerData.some((data) => data.providerId === 'password');
    const credential = GoogleAuthProvider.credentialFromResult(result);

    return { user, credential, hasGoogleLogin, hasEmailLinkLogin };
  }
  return null;
};

export const forceLogout = async () => {
  window.localStorage.removeItem('currentUser'); // Remove user data from local storage
  await signOut(auth); // Sign out the user with Firebase
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
